import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocalStoreService } from "../../shared/services/local-store.service";

import { Documents } from "../../shared/models/documents.model";

@Injectable()
export class DocumentsService {
  private baseUrl: string = '';
  private collection: string = 'documents';
  public owner: string;
  public account: string;
  public ownerName: string;

  items: any[];

  constructor(private http: HttpClient, public local: LocalStoreService) {
    this.baseUrl=this.local.getItem('url.base')+'/'+this.collection; 

    this.owner    = this.local.getItem('user.id');  
    this.account  = this.local.getItem('user.account'); //this.local.getItem('user.account'); 
    this.ownerName   = this.local.getItem('user.fullname');  
    

    let itemSub: Subscription;
  }



  // get by id
  findById(id): Observable<any> {
    return this.http.get(this.baseUrl+'/id/'+id);
  }

  // get by owner
  findByOwner(): Observable<any> {
    return this.http.get(this.baseUrl+'/owner/'+this.owner);
  }

  // get by account
  findByAccount(): Observable<any> {
    return this.http.get(this.baseUrl+'/account/'+this.account);
  }
 
  // get by campaign
  findByCampaign(id): Observable<any> {
    return this.http.get(this.baseUrl+'/campaign/'+id);
  }

  // get by company
  findByCompany(id): Observable<any> {
    return this.http.get(this.baseUrl+'/company/'+id);
  }

  // get by project
  findByProject(id): Observable<any> {
    return this.http.get(this.baseUrl+'/project/'+id);
  }

    // get by funnel
    findByFunnel(id): Observable<any> {
      return this.http.get(this.baseUrl+'/funnel/'+id);
    }
    
  
  // get by task
  findByTask(id): Observable<any> {
    return this.http.get(this.baseUrl+'/task/'+id);
  }  

  // get by contact
  findByContact(id): Observable<any> {
    return this.http.get(this.baseUrl+'/contact/'+id);
  }

  download(filename): Observable<Blob> {
    let url = this.baseUrl+'/download/'+filename;
    return this.http.get(url, {responseType: 'blob'})
  }

  downloadByDocument(document: Documents): Observable<Blob> {
    let url = this.baseUrl+'/download/'+document.name+'/folder/'+document.folder+'/owner/'+document.owner;
    return this.http.get(url, {responseType: 'blob'})
  }

  downloadByDocumentAndAccount(document: Documents): Observable<Blob> {
    let url = this.baseUrl+'/download/'+document.name+'/folder/'+document.folder+'/account/'+document.account;
    console.log(url);
    return this.http.get(url, {responseType: 'blob'})
  }



  downloadByName(name): Observable<Blob> {
    let url = this.baseUrl+"/download/"+name+"/folder/|/account/"+this.account;
    return this.http.get(url, {responseType: 'blob'})
  }


  // get by lead
  findByLead(id): Observable<any> {
    return this.http.get(this.baseUrl+'/lead/'+id);
  }

  // get by deal
  findByDeal(id): Observable<any> {
    return this.http.get(this.baseUrl+'/deal/'+id);
  }



  // add 
  create(item){
    item.recordId = Math.round(Math.random() * 10000000000).toString();
    item.pubDate = new Date();
    item.owner = this.owner;
    item.account = this.account;
    item.ownerName = this.ownerName;

    return this.http.post(this.baseUrl, item);
  }

  update(item){
    this.http.put(this.baseUrl, item).subscribe(res => console.log(res )); 
    this.items=[];
    this.items.unshift(item);
    return of(this.items.slice()).pipe(delay(100));
  }

   // delete demande
   delete(row, demandes) {
    let id = row.id;
    this.items = demandes;

    let i = this.items.indexOf(row);
    let index = 0;
    for (let item of this.items) {
      if (item.id==id){
        i=index;
      } 
      index++;
    }

    this.baseUrl = this.local.getItem('url.base')+'/pricing/remove'; 
    this.http.delete(this.baseUrl+'/'+id).subscribe(res => console.log(res ));
    this.items.splice(i, 1);
    return of(this.items.slice()).pipe(delay(100));
  } 


}
