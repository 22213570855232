
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { Agent } from "../shared/models/Agent.model";
import { LocalStoreService } from "../shared/services/local-store.service";
import { Subscription } from 'rxjs';
import { LoginService } from './login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public signinForm: FormGroup;
  public errorMsg = '';
  private _unsubscribeAll: Subject<any>;
  public items: any[];
  public getItemSub: Subscription;
  public token:string;
  public ipAddress: any;
  public agent: Agent;
  public isAuthenticated: boolean = false;

  public currentLang: string = "English";

  constructor(
    private router: Router,
    private local: LocalStoreService,
    private loginService: LoginService) { 

      local.clear();
      this.local.setItem('token',null);
      this.local.setItem('user',null);
    }

  ngOnInit(): void {

    this.signinForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    })

  }


  signin() {
    const signinData = this.signinForm.value;
    if (signinData) {
        this.loginService.login(signinData.email, signinData.password )
                          .subscribe(data => {
                            this.items = data;
                            data.language = "fr-FR";
                            if(data != null){
                                this.isAuthenticated = true;
                                // all user here
                                this.local.setItem('user',data);
                                this.local.setItem('token',data.token);
                                this.local.setItem('isAuthenticated',true);
                                this.local.setItem('user.id',data.id);
                                this.local.setItem('user.profile',data.profile);
                                this.local.setItem('user.account',data.account);
                                this.local.setItem('user.organizationId',data.organizationId);
                                this.local.setItem('user.username',data.username);
                                this.local.setItem('user.firstname',data.firstName);
                                this.local.setItem('user.lastname',data.lastName);
                                this.local.setItem('user.title',data.title);
                                this.local.setItem('user.description',data.description);
                                this.local.setItem('user.token',data.token);
                                this.local.setItem('user.apikey',data.apiKey);
                                this.local.setItem('user.email',data.email);
                                this.local.setItem('user.phone',data.phone);
                                this.local.setItem('user.address',data.address);
                                this.local.setItem('user.website',data.website);
                                this.local.setItem('user.userType',data.userType);
                                this.local.setItem('user.language',data.language);
                                this.local.setItem('user.fullname',data.firstName+" "+data.lastName);

                                switch(data.language) { 
                                    case 'en-US': { 
                                      this.local.setItem('currentLang', 'English');
                                      break; 
                                    } 
                                    case 'fr-FR': { 
                                      this.local.setItem('currentLang', 'French');
                                      break; 
                                    } 
                                    default: { 
                                      this.local.setItem('currentLang', 'English');
                                      break; 
                                    } 
                                 } 
                                this.local.setItem('url.base','https://evoltys-technologies.net:8443/api/v1');
                                //this.local.setItem('url.base',' https://localhost:8443/api/v1');
                          
                                
                                if (data.profile == "CUSTOMER")
                                  this.router.navigate(['/invoices/list']);
                                else
                                  this.router.navigate(['/dashboard']);


                            } 
                          })
    }
  }


  
  

}
