import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Error404Component } from "./error404/error404.component";
import { Error500Component } from "./error500/error500.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ForgotComponent } from "./forgot/forgot.component";
import { HomeComponent } from "./all-modules/home/home.component";
import { InvoicesPrintComponent } from './all-modules/invoices/invoices-print/invoices-print.component';
import { QuotesPrintComponent } from './all-modules/quotes/quotes-print/quotes-print.component';
import { PaymentsPrintComponent } from './all-modules/payments/payments-print/payments-print.component';
import { EntriesPrintComponent } from './all-modules/accounting/entries-print/entries-print.component';
import { ReportsPrintComponent } from './all-modules/reports/reports-print/reports-print.component';
import { OrdersPrintComponent } from './all-modules/orders/orders-print/orders-print.component';
import { DeliveriesPrintComponent } from './all-modules/deliveries/deliveries-print/deliveries-print.component';

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import(`./all-modules/all-modules.module`).then(
        (m) => m.AllModulesModule
      ),
  },
   { path: "error-404", component: Error404Component },
   { path: "error-500", component: Error500Component },
   { path: "login", component: LoginComponent },
   { path: "register", component: RegisterComponent },
   { path: "forgot", component: ForgotComponent },
   { path: "home", component: HomeComponent },
   {
    path: 'invoices/print/:id',
    component: InvoicesPrintComponent,
    data: { title: 'Invoice print', breadcrumb: 'INVOICESPRINT' }
   },
   {
    path: 'quotes/print/:id',
    component: QuotesPrintComponent,
    data: { title: 'Quote print', breadcrumb: 'QUOTESPRINT' }
   },
   {
      path: 'payments/print/:id',
      component: PaymentsPrintComponent,
      data: { title: 'Payment print', breadcrumb: 'PAYMENTSPRINT' }
   },
   {
      path: 'reports/print/:id',
      component: ReportsPrintComponent,
      data: { title: 'Report print', breadcrumb: 'REPORTSPRINT' }
   },
   {
      path: 'entries/print/:id',
      component: EntriesPrintComponent,
      data: { title: 'Entries print', breadcrumb: 'ENTRIESPRINT' }
    },
    {
       path: 'orders/print/:id',
       component: OrdersPrintComponent,
       data: { title: 'Orders print', breadcrumb: 'ORDERSPRINT' }
     },
     {
        path: 'deliveries/print/:id',
        component: DeliveriesPrintComponent,
        data: { title: 'Deliveries print', breadcrumb: 'DELIVERIESSPRINT' }
      }



];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
