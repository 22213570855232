import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataTablesModule } from "angular-datatables";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
import { Error404Component } from './error404/error404.component';
import { Error500Component } from './error500/error500.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TokenInterceptorProvider } from './shared/interceptors/token.interceptor';


import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { LoginService } from './login/login.service';

import { RegisterService } from './register/register.service';
import { InvoicesService } from './all-modules/invoices/invoices.service';
import { QuotesService } from './all-modules/quotes/quotes.service';
import { PaymentsService } from './all-modules/payments/payments.service';
import { AccountsService } from './all-modules/accounts/accounts.service';
import { DocumentsService } from './all-modules/documents/documents.service';
import { AccountingService } from './all-modules/accounting/accounting.service';
import { OrdersService } from './all-modules/orders/orders.service';
import { DeliveriesService } from './all-modules/deliveries/deliveries.service';


import { ReferentialService } from "./shared/services/referential.service";
import { CompaniesService } from './all-modules/companies/companies.service';
import { ContactsService } from './all-modules/contacts/contacts.service';
import { CampaignsService } from './all-modules/campaigns/campaigns.service';
import { NotesService } from './all-modules/notes/notes.service';
import { LeadsService } from './all-modules/leads/leads.service';
import { ActivitiesService } from './all-modules/activities/activities.service';
import { ProjectsService } from './all-modules/projects/projects.service';
import { TasksService } from './all-modules/tasks/tasks.service';
import { DealsService } from './all-modules/deals/deals.service';
import { CollectionsService } from './all-modules/reports/collections.service';

import { ReportsService } from './all-modules/reports/reports.service';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ForgotComponent } from './forgot/forgot.component';

import {APP_BASE_HREF} from '@angular/common'; 

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    Error404Component,
    Error500Component,
    LoginComponent,
    RegisterComponent,
    ForgotComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    DataTablesModule,
    HttpClientModule,
    FormsModule, ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
      defaultLanguage: 'en-US',
    })
  ],
   providers: [LoginService,RegisterService,InvoicesService,QuotesService, PaymentsService, AccountsService, DocumentsService,ReportsService,
                CollectionsService, AccountsService,
                CompaniesService, ContactsService, CampaignsService, NotesService, ActivitiesService,
                LeadsService, ProjectsService, TasksService, DealsService, ReferentialService, 
                AccountingService, OrdersService, DeliveriesService, TokenInterceptorProvider,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
     {provide: APP_BASE_HREF, useValue: '/'} 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
