<!-- Start Invoice-->
<div class="invoice overflow-auto"   id="print-area">
	<div>
		<header>
			<div class="row">
				<div class="col">
					<a href="">
						<img [src]="logoLarge" alt="Image" style="width:150px;">
					</a>
				</div>
				<div class="col company-details">
					<h2 class="name">
						<a target="_blank" href="" (click)="$event.preventDefault()">
						{{item.sellerOrganization}}
						</a>
					</h2>
					<div>{{item.sellerAddress}}</div>
					<div>{{item.sellerPostalCode}} {{item.sellerCity}}</div>
					<div>{{item.sellerCountry}}</div>
				</div>
			</div>
		</header>
		<main>
			<div class="row contacts">
				<div class="col invoice-to">
					<!-- <div class="text-gray-light">{{ "ORDER TO" | translate }}:</div> -->
					<h2 class="to">{{item.buyerOrganization}}</h2>
					<div class="address">{{item.buyerAddress}}</div>
					<div class="address">{{item.buyerPostalCode}} {{item.buyerCity}}</div>
					<div>{{item.buyerCountry}}</div>
				</div>
				<div class="col invoice-details">
					<h4 class="invoice-id">{{ "DELIVERY NOTE" | translate }} : {{item.deliveryNumber}}</h4>
					<div class="date">{{ "Order" | translate }}: {{item.orderNumber}}</div>
					<div class="date">{{ "Order Date" | translate }}: {{item.issueDate |date : 'dd/MM/yyyy'}}</div>
					<div class="date">{{ "Delivery Date" | translate }}: {{item.deliveryDate |date : 'dd/MM/yyyy'}}</div>
				</div>
			</div>
			<table>
				<thead>
					<tr>
						<th class="text-left">{{ "Reference" | translate }}</th>
						<th class="text-left">{{ "Description" | translate }}</th>
						<th class="text-center">{{ "Quantity" | translate }}</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let row of item.invoiceItems;let i = index">
						<td class="no">{{row.reference}}</td>
						<td class="text-left">
							<h3>{{row.name}}</h3>{{row.description}}
						</td>
						<td class="text-center">{{row.quantity}}</td>
					</tr>
				</tbody>
			</table>


			<div class="row contacts">
				<div>{{ "Tracking Number" | translate }} : {{item.trackingNumber}}</div>
				<div>{{ "Tracking Url" | translate }} : {{item.trackingUrl}}</div>
			</div>


            <div class="notices">
                <div><p class="text-left">{{item.notes}}</p></div><br/>
                <div><p class="text-left">{{item.termsAndConditions}}</p></div>
            </div>



		</main>
		<footer> </footer>
	</div>
	<!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
	<div></div>
</div>
<!-- End Invoice-->