import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { LocalStoreService } from "../shared/services/local-store.service";

import { RegisterService } from './register.service';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  public currentLang: string = 'en';
  public language: string = "English";

  public loading:boolean = true;
  public errorMessage: string = "";

  public owner: string;
  public account: string;
  public registerForm: FormGroup;
  public texte: string;
  
  public items: any[];
  public companies: any[];
  public members: any[];


  constructor(public registerService: RegisterService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              public translateService: TranslateService,
              public local: LocalStoreService,)
            {

              this.language = this.local.getItem('user.language');
              this.translateService.use(this.language);

               this.registerForm = new FormGroup({
                firstName: new FormControl('', [Validators.required]),      
                lastName: new FormControl('', [Validators.required]),  
                email: new FormControl('', [Validators.required]),
                password: new FormControl('', [Validators.required]),
                repeatPassword: new FormControl('', [Validators.required]),
                organization: new FormControl('', [Validators.required]),
                address: new FormControl('', []),
                postalCode: new FormControl('', []),
                city: new FormControl('', []),
                country: new FormControl('', []),
                language: new FormControl('', []),
                couponCode: new FormControl('', [])
              });
   }

  ngOnInit(): void {
  }


  onSubmit() {

    if (this.registerForm.controls['lastName'].value == '' ){
      alert ("Enter your Last Name, Please");
      return;
    }

    if (this.registerForm.controls['firstName'].value == '' ){
      alert ("Enter your First Name, Please");
      return;
    }
    

    if (this.registerForm.controls['email'].value == '' ){
      alert ("Enter your Email, Please");
      return;
    }

    if (this.registerForm.controls['password'].value == ''){
      alert ("Enter your Password, Please");
      return;
    }

    if (this.registerForm.controls['password'].value  != this.registerForm.controls['repeatPassword'].value ){
      alert ("Password and repeat Password are different");
      return;
    }

    if (this.registerForm.controls['language'].value == '' ){
      alert ("Select your language, Please");
      return;
    }

    if (this.registerForm.controls['organization'].value == '' ){
      alert ("Enter your Company Name, Please");
      return;
    }

    if (!this.registerForm.invalid) {
      const itemData = this.registerForm.value;
      this.registerService.create(itemData)
                            .subscribe(
                              (response) => {                           //Next callback
                                this.onClose();
                              },
                              (error) => {                              //Error callback
                                if (error.statusText="OK"){
                                  this.onClose();
                                }
                              }
                            )
    }
  }


  onClose() {
    this.location.back();
  }  
}
