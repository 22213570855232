import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LocalStoreService } from "../shared/services/local-store.service";

@Injectable()
export class RegisterService {
  private baseUrl: string = '';
  private collection: string = 'accounts/register';
  public owner: string;
  public account: string;
  public ownerName: string;

  items: any[];

  constructor(private http: HttpClient, public local: LocalStoreService) {
    this.baseUrl=this.local.getItem('url.base');
    if (this.baseUrl == null){
      this.baseUrl="https://evoltys-technologies.net:8443/api/v1";
    }
    this.baseUrl=this.baseUrl+'/'+this.collection;
    let itemSub: Subscription;
  }



  // get by id
  findById(id): Observable<any> {
    return this.http.get(this.baseUrl+'/id/'+id);
  }


  // get by company
  findByCompany(id): Observable<any> {
    return this.http.get(this.baseUrl+'/company/'+id);
  }
  


  // add 
  create(item){
    item.recordId = Math.round(Math.random() * 10000000000).toString();
    item.creationDate = new Date();
    item.username = item.email; 

    return this.http.post(this.baseUrl, item);
  }

  update(item){
    this.http.put(this.baseUrl, item).subscribe(res => console.log(res )); 
    this.items=[];
    this.items.unshift(item);
    return of(this.items.slice()).pipe(delay(100));
  }



}
